import React, { Component } from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// styled components
import styled from "styled-components";

// images
import ContactCallIcon from "../../assets/img/icon/Contact.svg";
import ContactEmailIcon from "../../assets/img/icon/Email.svg";
import ContactLocationIcon from "../../assets/img/icon/Location.svg";

const ContactContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
`;
const ContactContent = styled.div`
display: grid;
gap: 20px;
grid-template-columns: repeat(2, 1fr);
@media screen and (max-width: 768px) {
  grid-template-columns: repeat(1, 1fr);
}
`;
const ContactInfo = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-start;
a {
  color: #000;
  display: flex;
  padding: 5px;
  align-items: center;
  img {
    padding: 5px;
    margin-right: 5px;
    border-radius: 50px;
    background-color: #ccc;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
`;
const ContactForm = styled.form`
display: flex;
align-items: flex-start;
flex-direction: column;
justify-content: center;
textarea {
  width: 100%;
  outline: none;
  padding: 15px 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
}
button {
  width: max-content;
  padding: 15px 20px;
  margin-top: 10px;
  border: none;
  color: #fff;
  background: #12d4b1;
  cursor: pointer;
  border-radius: 50px;
}
`;

function contactFormWhatsapp() {
  const form = document.querySelector(".formWhatsapp");
  const input = document.querySelector(".formTextarea");
  form.addEventListener("submit", (e) => {
    e.preventDefault();
    let inputValue = input.value;
    const url = `whatsapp://send?phone=+989158910345&text=${inputValue} - send from website`;
    window.open(url);
  });
}

class Contact extends Component {

  render() {
    return (
      <ContactContainer id="contact">
        <h2>Contact us</h2>
        <ContactContent>
        <ContactInfo>
          <a href="tel:989151578779" data-aos="fade-down" data-aos-duration="1500">
            <img src={ContactCallIcon} alt="Contact Icon" />
            <p>Phone: +98 9151578779</p>
          </a>
          <a href="mailto:rastankala.co@gmail.com" data-aos="fade-down" data-aos-duration="1800">
            <img src={ContactEmailIcon} alt="Email Icon" />
            <p>Email: rastankala.co@gmail.com</p>
          </a>
          <a href="#" data-aos="fade-down" data-aos-duration="2100">
            <img src={ContactLocationIcon} alt="Location Icon" />
            <p>Address: Iran, Khorasan Razavi, Mashhad</p>
          </a>
        </ContactInfo>
        <div>
          <ContactForm onClick={contactFormWhatsapp} className="formWhatsapp">
          <textarea className="formTextarea" cols="30" rows="10" placeholder="Type your masseage..."></textarea>
          <button type="submit">Send on whatsapp</button>
          </ContactForm>
        </div>
        </ContactContent>
      </ContactContainer>
    );
  }
}

export default Contact;