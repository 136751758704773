import React, { Component } from "react";

import "../../index.css";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";

// styled components
import styled from "styled-components";

// images
import Certificate1 from "../../assets/img/certificates1.jpg";
import Certificate2 from "../../assets/img/certificates2.jpg";
import Certificate3 from "../../assets/img/certificates3.jpg";
import Certificate4 from "../../assets/img/certificates4.jpg";
import Certificate5 from "../../assets/img/certificates5.jpg";

const CertificatesContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
  h2 {
  }
`;
const CertificateImage = styled.div`
  padding: 10px;
  img {
    width: 100%;
  }
`;

class Certificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificateData: [
        {
          id: 301,
          img: Certificate1,
        },
        {
          id: 302,
          img: Certificate2,
        },
        {
          id: 303,
          img: Certificate3,
        },
        {
          id: 304,
          img: Certificate4,
        },
        {
          id: 305,
          img: Certificate5,
        },
      ],
    };
  }

  render() {
    return (
      <CertificatesContainer id="certificates">
        <h2>Certificates</h2>
        <p>You can see some of Rastan's certificates and honors</p>

        <Swiper
          spaceBetween={20}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          cssMode={true}
          navigation={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
          breakpoints={{
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1280: {
              slidesPerView: 3,
            },

            1536: {
              slidesPerView: 3,
            },
          }}
        >
          {this.state.certificateData.map((item, index) => {
            return (
              <SwiperSlide key={item.id}>
                <CertificateImage data-aos="zoom-in" data-aos-duration="1500">
                  <img src={item.img} alt={`Certificate Photo ${index}`} />
                </CertificateImage>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </CertificatesContainer>
    );
  }
}

export default Certificates;
