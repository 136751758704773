import React, { Component } from "react";

// styled components
import styled from "styled-components";

// images
import Logo from "../../assets/img/Logo.png";
import Shape1 from "../../assets/img/shape1.png";
import HomeIcon from "../../assets/img/icon/Home.svg";
import AboutIcon from "../../assets/img/icon/About.svg";
import ProductsIcon from "../../assets/img/icon/Products.svg";
import CertificatesIcon from "../../assets/img/icon/Certificates.svg";
import ContactIcon from "../../assets/img/icon/Contact.svg";

const HeaderContainer = styled.div`
  margin: 0 auto;
  margin-top: 35px;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;
const LogoImg = styled.a`
  img {
    max-width: 70%;
  }
  @media screen and (max-width: 768px) {
    img {
      max-width: 50%;
    }
  }
`;
const Shape = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 100%;
  }
`;
const Lists = styled.div`
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    cursor: pointer;
    list-style: none;
    margin-left: 30px;
    font-size: 18px;
    img {
      margin-right: 5px;
      width: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    ul {
      height: max-content;
      padding: 0px 20px;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      animation: fadeInDown 0.3s forwards;
      transition: all 0.3s linear;
      background-color: #fff;
      position: absolute;
      top: 10px;
      right: 10px;
      left: 10px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      display: ${(props) => (props.open ? "block" : "none")};
    }
    li {
      padding: 10px 15px;
      margin-bottom: 10px;
      margin-left: -10px;
      border-radius: 10px;
      transition: all 0.3s linear;
    }
    li:nth-child(1) {
      margin-top: 60px;
    }
    li:hover {
      color: #f0fff4;
      padding: 10px 17px;
      background: #455a64;
    }
    @keyframes fadeInDown {
      0% {
        opacity: 0;
        transform: translateY(5%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
`;
const Hamburger = styled.div`
  z-index: 99999;
  cursor: pointer;
  div {
    width: 25px;
    height: 3px;
    margin-bottom: 5px;
    background: #666666;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.2s linear;
    &:nth-child(1) {
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0deg)")};
    }
    &:nth-child(2) {
      transform: ${(props) =>
        props.open ? "translateX(-100%)" : "translateX(0)"};
      opacity: ${(props) => (props.open ? "0" : "1")};
    }
    &:nth-child(3) {
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0deg)")};
    }
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  hamburgerClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  home = () => {
    window.location.replace("https://rastangroup.com/");
  };

  about = () => {
    const element = document.getElementById("about");
    element.scrollIntoView();
  };

  products = () => {
    const element = document.getElementById("products");
    element.scrollIntoView();
  };

  certificates = () => {
    const element = document.getElementById("certificates");
    element.scrollIntoView();
  };

  contact = () => {
    const element = document.getElementById("contact");
    element.scrollIntoView();
  };

  render() {
    return (
      <React.Fragment>
        <Shape>
          <img src={Shape1} alt="shape 1" />
        </Shape>
        <HeaderContainer>
          <LogoImg href="https://rastangroup.com/">
            <img src={Logo} alt="Rastan Logo" />
          </LogoImg>

          <Lists open={this.state.open}>
            <ul>
              <li onClick={this.home}>
                <img src={HomeIcon} alt={`${HomeIcon} icon`} />
                Home
              </li>
              <li onClick={this.about}>
                <img src={AboutIcon} alt={`${AboutIcon} icon`} />
                About
              </li>
              <li onClick={this.products}>
                <img src={ProductsIcon} alt={`${ProductsIcon} icon`} />
                Products
              </li>
              <li onClick={this.certificates}>
                <img src={CertificatesIcon} alt={`${CertificatesIcon} icon`} />
                Certificates
              </li>
              <li onClick={this.contact}>
                <img src={ContactIcon} alt={`${ContactIcon} icon`} />
                Contact
              </li>
            </ul>
          </Lists>

          <Hamburger open={this.state.open} onClick={this.hamburgerClick}>
            <div></div>
            <div></div>
            <div></div>
          </Hamburger>
        </HeaderContainer>
      </React.Fragment>
    );
  }
}

export default Header;
