import React, { Component } from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// styled components
import styled from "styled-components";

// images
import ProductCategoryImage from "../../assets/img/our-product.png";
import ProductIcon from "../../assets/img/icon/Document.svg";

const ProductContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  img {
    width: 100%;
    animation: Up-and-down-animations 5s linear infinite;
  }
  @keyframes Up-and-down-animations {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(10px);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  h2 {
    text-align: center;
    margin-bottom: 10px;
    span {
      color: #a1304a;
    }
  }
`;
const ProductContent = styled.div`
  display: grid;
  gap: 50px;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 768px) {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
const ProductAllSide = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
`;
const LinkItem = styled.div`
  margin-bottom: 30px;
`;
const ProductItemTitle = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 30px;
    animation: 0;
  }
`;

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductItemData: [
        {
          id: 201,
          name: "Servo motor",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/Servomotor",
        },
        {
          id: 202,
          name: "CNC Parts",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/CNC_router",
        },
        {
          id: 203,
          name: "Inverter",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/Power_inverter",
        },
        {
          id: 204,
          name: "PLC",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/Programmable_logic_controller#:~:text=A%20programmable%20logic%20controller%20(PLC,programming%2C%20and%20process%20fault%20diagnosis.",
        },
        {
          id: 205,
          name: "HMI",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/HMI",
        },
        {
          id: 206,
          name: "Stepper motor",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/Stepper_motor",
        },
        {
          id: 207,
          name: "Soft starter",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/Motor_soft_starter",
        },
        {
          id: 208,
          name: "Diesel generator",
          img: ProductIcon,
          time: "1500",
          link: "https://en.wikipedia.org/wiki/Diesel_generator",
        },
      ],
    };
  }

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <ProductContainer id="products">
        <div>
          <h2>
            Check <span>our</span> Product
          </h2>
        </div>
        <ProductContent>
          <div data-aos="zoom-in" data-aos-duration="1500">
            <img src={ProductCategoryImage} alt="our product image" />
          </div>
          <ProductAllSide>
            {this.state.ProductItemData.map((list) => (
              <div key={list.id} data-aos="fade-right" data-aos-duration="1500">
                <ProductItemTitle>
                  <img src={list.img} alt={`${list.name} Icon`} />
                  <h5 name={list.name}>{list.name}</h5>
                </ProductItemTitle>
                <LinkItem>
                  <a href={list.link}>Leran more...</a>
                </LinkItem>
              </div>
            ))}
          </ProductAllSide>
        </ProductContent>
      </ProductContainer>
    );
  }
}

export default Product;