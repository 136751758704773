import React, { Component } from "react";

// styled components
import styled from "styled-components";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// images
import HeroImage from "../../assets/img/HeroImg.png";

const HeroContainer = styled.div`
  height: calc(100vh - 110px);
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    height: auto;
    margin-top: 50px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
const HeroButton = styled.button`
  width: max-content;
  padding: 15px 20px;
  margin-top: 20px;
  border: none;
  color: #fff;
  background: #12d4b1;
  cursor: pointer;
  border-radius: 50px;
`;
const HeroImg = styled.div`
  img {
    width: 100%;
    animation: Up-and-down-animations 5s linear infinite;
  }
  @keyframes Up-and-down-animations {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(10px);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 100%;
    }
  }
`;

class Hero extends Component {
  componentDidMount() {
    AOS.init();
  }

  contact = () => {
    const element = document.getElementById("contact");
    element.scrollIntoView();
  }

  render() {
    return (
      <React.Fragment>
        <HeroContainer onMouseMove={this.MoveHandeler}>
          <div data-aos="fade-right" data-aos-duration="1500">
            <h1>Rastan Engineering Company</h1>
            <HeroButton onClick={this.contact}>Contact us</HeroButton>
          </div>
          <HeroImg data-aos="zoom-in" data-aos-duration="1500">
            <img src={HeroImage} alt="hero image" />
          </HeroImg>
        </HeroContainer>
      </React.Fragment>
    );
  }
}

export default Hero;
