import React, { Component } from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// styled components
import styled from "styled-components";

// images
import programingVector from "../../assets/img/programing-vector.svg";

const AutomationContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  gap: 70px;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  img {
    width: 100%;
    animation: Up-and-down-animations 5s linear infinite;
  }
  @keyframes Up-and-down-animations {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(10px);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  h2 {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
const AutomationText = styled.div`
  order: 2;
`;

class Automation extends Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <AutomationContainer>
        <AutomationText>
          <h2>What is industrial automation?</h2>
          <p>
            Industrial automation is the control of machinery and processes used
            in various industries by autonomous systems through the use of
            technologies like robotics and computer software.
            <br />
            Industries implement automation to increase productivity and reduce
            costs related to employees, their benefits and other associated
            expenses, while increasing precision and flexibility.
            <br />
            With the Industrial Revolution came mechanization, which brought
            cheaper and more plentiful goods. Generally, the mechanical
            processes in industries were faster and produced greater quantities
            of goods but still required skilled workers. Not only did machines
            require operators but when errors occurred, they would waste
            materials, cause production issues and even damage equipment.
            <br />
            With the arrival of automation, control loops were added to machine
            operation. These can be open control loops that allow for human
            input or closed loops which are fully automated. Industrial control
            systems (ICS) allow for monitoring and control locally and remotely.
            With these increasingly advanced control mechanisms, industries can
            operate 24 hours a day. Productivity has increased, errors are
            reduced and quality is improved. However, automation does have some
            negative impact, including high initial costs, reduced worker
            employment and the elimination of some ethical human oversight. As
            automation continues to advance and gain popularity in new
            industries, it is possible to see these events increase.
            <br />
            Recent advancements in automation in industrial production are
            focused on flexibility and quality. Manufacturing flexibility not
            only allows for more product types, but also lets consumers order
            customized products that are automatically produced.
          </p>
        </AutomationText>
        <div
          data-aos="fade-up"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1500"
        >
          <img src={programingVector} alt="business vector" />
        </div>
      </AutomationContainer>
    );
  }
}

export default Automation;
