import React, { Component } from "react";

// styled components
import styled from "styled-components";

const BrandCard = styled.div`
  height: auto;
  padding: 10px 20px;
  background: #f0fff4;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-out;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.01);
  img {
    margin-top: 10px;
  }
  a {
    color: #000;
    margin-top: 10px;
    text-decoration: none;
  }
`;

class Brand extends Component {
  render() {
    const { image, name, link, timeDuration } = this.props;
    return (
      <React.Fragment>
        <BrandCard data-aos="fade-down" data-aos-duration={timeDuration}>
          <img src={image} alt={`${name} logo`} />
          <h3>{name}</h3>
          <a href={link}>Read more...</a>
        </BrandCard>
      </React.Fragment>
    );
  }
}

export default Brand;
