import React, { Component } from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// styled components
import styled from "styled-components";

// images
import Logo from "../../assets/img/Logo.png";
import Instagramlogo from "../../assets/img/icon/Instagramlogo.png";
import LinkedInLogo from "../../assets/img/icon/LinkedInLogo.png";
import WhatsAppLogo from "../../assets/img/icon/WhatsAppLogo.png";
import TelegramLogo from "../../assets/img/icon/TelegramLogo.png";

const FooterWrapper = styled.div`
  background-color: #f4f4f4;
  @media screen and (max-width: 768px) {
    border-radius: 20px;
  }
`;
const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  h6 {
    font-size: 2.3rem;
  }
  @media screen and (max-width: 768px) {
    padding: 25px 20px;
    border-radius: 20px;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    h6 {
      font-size: 1.8rem;
    }
  }
`;
const FooterLogo = styled.a`
  img {
    width: 150px;
    @media screen and (max-width: 768px) {
      width: 130px;
    }
  }
`;
const FooterSocial = styled.div`
  a {
    margin-right: 20px;
    img {
      margin-top: 10px;
    }
  }
`;

const Copyright = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background: #ccc;
  @media screen and (max-width: 768px) {
    border-radius: 0 0 20px 20px;
  }
`;

class Footer extends Component {
  render() {
    return (
      <FooterWrapper>
        <FooterContainer>
          <FooterLogo href="https://rastangroup.com/">
            <img src={Logo} alt="Rastan Logo" />
          </FooterLogo>
          <FooterSocial>
            <h6>Our social media</h6>
            <a href="https://instagram.com/rastankala">
              <img src={Instagramlogo} alt="Instagram Logo" />
            </a>
            <a href="https://www.linkedin.com/company/rastangroup/">
              <img src={LinkedInLogo} alt="LinkedIn Logo" />
            </a>
            <a href="https://wa.me/9899158910345">
              <img src={WhatsAppLogo} alt="WhatsApp Logo" />
            </a>
            <a href="https://t.me/RastanGroup_Co">
              <img src={TelegramLogo} alt="Telegram Logo" />
            </a>
          </FooterSocial>
        </FooterContainer>
        <Copyright>Copyright© all rights reserved 2002, 2023</Copyright>
      </FooterWrapper>
    );
  }
}

export default Footer;
