import React, { Component } from "react";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

// styled components
import styled from "styled-components";

// images
import businessVector from "../../assets/img/business-vector.png";

const AboutContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  gap: 50px;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  img {
    width: 100%;
    animation: Up-and-down-animations 5s linear infinite;
  }
  @keyframes Up-and-down-animations {
    0% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(10px);
    }
    75% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  h5 {
    margin-top: 10px;
    margin-bottom: 10px;

    overflow: hidden;
    white-space: nowrap;
    border-right: 2px solid;
    width: 0;
    animation: typing;
    animation-duration: 3s;
    animation-timing-function: steps(30, end);
    animation-fill-mode: forwards;
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 25%;
    }
  }
  @media screen and (max-width: 768px) {
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 35%;
      }
    }
  }
`;

class About extends Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <AboutContainer id="about">
        <div>
          <h2>About Rastan company</h2>
          <h5>Scince 2002</h5>
          <p>
            Rastan was established in Iran in 2002 and with the aim of obtaining
            the satisfaction of you, dear customers, it offers its products
            exclusively, without intermediaries and with the best quality and
            price. Due to having a strong technical team in the field of
            electronic engineering, electricity and precision instruments,
            Rastan engineers have successfully completed many projects. Also, in
            order to choose the best solution for industrial projects and also
            to choose the best product according to the needs of customers,
            Rastan has considered a 24-hour consultation system for you dear
            ones. Rastan is an importer of inverter, servo motor, cnc and PLC
            parts, HMI, etc. in Iran with an experienced staff with more than
            twenty years of experience.
          </p>
        </div>
        <div
          data-aos="fade-down"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1500"
        >
          <img src={businessVector} alt="business vector" />
        </div>
      </AboutContainer>
    );
  }
}

export default About;
