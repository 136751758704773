import React, { Component } from "react";

// styled components
import styled from "styled-components";

// single card
import Brand from "./Brand";

// images
import invtLogo from "../../assets/img/logo/invtLogo.png";
import sanyuLogo from "../../assets/img/logo/sanyuLogo.png";
import deltaLogo from "../../assets/img/logo/deltaLogo.png";
import zestLogo from "../../assets/img/logo/zestLogo.png";

const BrandsContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const BrandsTitle = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  span {
    color: #a1304a;
  }
`;

const BrandsDescription = styled.div`
  margin-bottom: 20px;
  display: grid;
  gap: 20px;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  img {
    width: 50%;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    img {
      width: 70%;
    }
  }
`;

class Brands extends Component {
  constructor() {
    super();
    this.state = {
      cardData: [
        {
          id: 1,
          name: "Invt",
          img: invtLogo,
          url: "https://www.invt.com",
          time: "1000",
        },
        {
          id: 2,
          name: "Zest",
          img: zestLogo,
          url: "https://www.zest-electric.com",
          time: "1300",
        },
        {
          id: 3,
          name: "Sanyu",
          img: sanyuLogo,
          url: "https://www.zest-electric.com",
          time: "1900",
        },
        {
          id: 4,
          name: "Delta",
          img: deltaLogo,
          url: "https://www.delta.com",
          time: "1500",
        },
      ],
    };
  }

  render() {
    return (
      <BrandsContainer>
        <BrandsTitle>
          <h2>
            We are <span>different</span> because...
          </h2>
          <p>
            We are different because the products we sell are among the best
            brands in the world that have international quality and excellent
            after-sales service for industries.
          </p>
        </BrandsTitle>
        <BrandsDescription>
          {this.state.cardData.map((card) => (
            <Brand
              key={card.id}
              image={card.img}
              name={card.name}
              link={card.url}
              timeDuration={card.time}
            />
          ))}
        </BrandsDescription>
      </BrandsContainer>
    );
  }
}

export default Brands;
