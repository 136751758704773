import React, { Component } from "react";

// import components
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Brands from "./Components/Brands/Brands";
import About from "./Components/About/About";
import Automation from "./Components/Automation/Automation";
import Product from "./Components/Product/Product";
import Certificates from "./Components/Certificates/Certificates";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";

class App extends Component {
  componentDidMount() {
    document.title = "Rastan Engineering Company";
    console.log("Designed✏️ and developed👨🏻‍💻 by reza rastegar / https://rezarastegar.ir");
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <Hero />
        <Brands />
        <About />
        <Automation />
        <Product />
        <Certificates />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;